import React, { Component } from 'react';
import './index.scss';
import { Link, Redirect } from 'react-router-dom';
import Button from 'components/Button';
import toQueryString from 'to-querystring';
import jsonp from 'jsonp';

class EmailSign extends Component {

  oldUser = JSON.parse(window.localStorage.getItem('user'));

  state = {
    terms: this.oldUser && this.oldUser.terms,
    error: false,
    fieldError: false,
    email: this.oldUser && this.oldUser.email,
    name: this.oldUser && this.oldUser.name,
    company: this.oldUser && this.oldUser.company,
    hasOldUser: this.oldUser && (
      this.oldUser.name || this.oldUser.email || this.oldUser.company),
  };

  componentWillUnmount = () => {
    const user = {
      email: this.state.email,
      name: this.state.name,
      company: this.state.company,
    };
    this.saveUser(user);
  }

  signTerms = () => {
    this.setState({
      terms: !this.state.terms,
      error: false,
    });
  }

  saveUser = () => {
    const user = {
      email: this.state.email,
      name: this.state.name,
      company: this.state.company,
      terms: this.state.company,
    };
    window.localStorage.setItem('user', JSON.stringify(user));
  }

  clearUser = () => {
    const user = {
      name: '',
      email: '',
      company: '',
      terms: false,
    };
    window.localStorage.setItem('user', JSON.stringify(user));
    this.setState({
      email: user.email,
      name: user.name,
      company: user.company,
      terms: user.terms,
      hasOldUser: false,
    });
  }

  validate = (e) => {
    e.preventDefault();

    const {
      email, name, company, terms,
    } = this.state;

    if (!terms) {
      this.setState({
        error: true,
      });
    } else if (!email || !name || !company || !terms) {
      this.setState({
        fieldError: true,
      });
    } else {
      this.saveUser();
      this.signup();
      // this.props.history.push('/quiz');

    }
  }

  handleResponse = (err, message) => {
    err && console.error('Error subscribing to mailchimp', err, message);
    this.setState({
      rederictTo: '/tack',
    });
  };

  signup = () => {
    const userData = window.localStorage.getItem('user');
    const { email, name, company } = userData
      ? JSON.parse(userData)
      : { email: false, name: false, company: false };

    const resultData = window.localStorage.getItem('result');
    const { succeeded, points } = resultData
      ? JSON.parse(resultData)
      : { succeeded: false, points: false };

    if (!succeeded || !email || !points || !name) {
      this.setState({
        pointsError: true,
      });
      console.error('Are you a cheater? :P');
      return;
    }

    this.setState({
      sending: true,
    });

    const params = toQueryString({
      'EMAIL': email,
      'NAME': name,
      'COMPANY': company,
      'POINTS': points,
    });

    const chimpId = '48ffe27643';
    const url = `https://fullystudios.us3.list-manage.com/subscribe/post?u=995d70e96eaa97d33554ed4b8&id=${chimpId}&${params}`;
    jsonp(
      url,
      {
        param: 'c',

      },
      this.handleResponse,
    );
    this.handleResponse();
  }

  render() {

    if (this.state.rederictTo) {
      return (
        <Redirect
          push
          to={{
            pathname: this.state.rederictTo,
            // eslint-disable-next-line react/prop-types
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="emailSign" pose="visible">
        <form
          onSubmit={this.validate}
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="emailSign__form validate"
          target="_blank"
          noValidate
        >
          {this.state.hasOldUser
            && (
              <div className="input-wrapper">
                <button
                  className="button button--small"
                  type="button"
                  onClick={this.clearUser}
                >
                  Rensa uppgifter
                </button>
              </div>
            )
          }
          <div className="flex--row input-row input-row-2">
            <div className="input-wrapper">
              <label htmlFor="mce-NAME">
                <span className="input-label">För- och efternamn</span>
                <input
                  type="text"
                  name="NAME"
                  className="required input"
                  id="mce-NAME"
                  required
                  placeholder="Förnamn Efternamn"
                  value={this.state.name}
                  onChange={(e) => { this.setState({ name: e.target.value }); }}
                  readOnly={this.state.stored}
                />
              </label>
            </div>
            <div className="input-wrapper">
              <label htmlFor="mce-COMPANY">
                <span className="input-label">Företag</span>
                <input
                  type="text"
                  name="COMPANY"
                  className="required input"
                  id="mce-COMPANY"
                  placeholder="Företag"
                  value={this.state.company}
                  onChange={(e) => {
                    this.setState({ company: e.target.value });
                  }}
                  readOnly={this.state.stored}
                />
              </label>
            </div>
          </div>
          <div className="flex--column full-width">
            <div className="input-wrapper">
              <label htmlFor="mce-EMAIL">
                <span className="input-label">E-post</span>
                <input
                  type="email"
                  name="EMAIL"
                  required
                  className="required input full-width"
                  id="mce-EMAIL"
                  placeholder="email@example.com"
                  value={this.state.email}
                  onChange={(e) => {
                    this.setState({
                      email: e.target.value,
                    });
                  }}
                  readOnly={this.state.stored}
                />
              </label>
            </div>
          </div>
          <div className="body-small">
            <div className="mc-field-group">
              <label
                htmlFor="terms-check"
                id="terms-check-label"
                className="checkbox"
              >
                <input
                  id="terms-check"
                  required
                  className="checkbox__trigger visuallyhidden"
                  name="terms-check"
                  type="checkbox"
                  checked={this.state.terms}
                  onChange={this.signTerms}
                />
                <span className="checkbox__symbol">
                  <svg
                    aria-hidden="true"
                    className="icon icon-checkbox"
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    version="1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Checkbox ikon</title><path d="M4 14l8 7L24 7" />
                  </svg>
                </span>
                <p className="checkbox__textwrapper">
                  För att delta i tävlingen måste du acceptera våra <Link className="terms-link" to="/villkor" rel="noopener noreferrer">villkor</Link>
                </p>
              </label>
              {
                this.state.error && (
                  <div className="error center">
                    <p className="c-primary error__message">
                      Läs igenom och acceptera <Link className="terms-link" to="/villkor" rel="noopener noreferrer">villkoren</Link> för att gå vidare.
                    </p>
                  </div>
                )
              }
              {
                this.state.fieldError && (
                  <div className="error center">
                    <p className="c-primary error__message">
                      Vänligen fyll i alla fält för att gå vidare.
                    </p>
                  </div>
                )
              }
              {
                this.state.pointsError && (
                  <div className="error center">
                    <p className="c-primary error__message">Vi kan inte hitta några poäng sparade. Har du vekligen spelat hela quizet?</p>
                    <Button href="/quiz">Spela igen!</Button>
                  </div>
                )
              }
            </div>
          </div>
          <div className="clear">
            {!this.state.pointsError && !this.state.sending && (
              <Button
                readOnly
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
              >
                Skicka in
              </Button>
            )}
            {this.state.sending
              && (
                <Button
                  readOnly
                  type="button"
                  disabled
                >
                  Skickar
                </Button>
              )
            }
          </div>
        </form>
      </div>
    );
  }
}

export default EmailSign;
