/* eslint-disable react/jsx-curly-spacing */
import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const YesNo = (props) => {
  const {
    isCorrect,
  } = props.question;
  return (
    <div className="yesno block-width-medium">
      <button
        type="button"
        className="yesno__button"
        onClick={e => props.callback(
          isCorrect === true,
          e,
          {
            x: e.clientX, y: e.clientY,
          },
        )}
      ><span>Ja</span>
      </button>
      <button
        type="button"
        className="yesno__button"
        onClick={e => props.callback(
          isCorrect === false,
          e,
          {
            x: e.clientX, y: e.clientY,
          },
        )}
      ><span>Nej</span>
      </button>
    </div>
  );
};

YesNo.propTypes = {
  question: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default YesNo;
