import React, { Component } from 'react';
import posed from 'react-pose';
import { shuffle } from 'helpers';
import PropTypes from 'prop-types';
// TODO: Bug. animeras inte fram längreq

import './index.scss';

const Form = posed.form({
  init: { opacity: 0 },
  loaded: { opacity: 1, staggerChildren: 50 },
});

// 2columns on portrait and 4 columns on landscape
const orientation = window.matchMedia('(orientation: landscape)').matches
  ? 'landscape'
  : 'portrait';

const clipPaths = {
  portrait: {
    init: 'inset(0% 0% 0% 0% round 3%)',
    loaded: 'inset(6% 6% 6% 6% round 3%)',
    hovered: 'inset(2% 2% 2% 2% round 3%)',
  },
  landscape: {
    init: 'inset(0% 0% 0% 0% round 3%)',
    loaded: 'inset(6% 6% 6% 6% round 3%)',
    hovered: 'inset(2% 2% 2% 2% round 3%)',
  },
};

const Image = posed.div({
  init: {
    webkitClipPath: clipPaths[orientation].init,
    clipPath: clipPaths[orientation].init,
  },
  loaded: {
    webkitClipPath: clipPaths[orientation].loaded,
    clipPath: clipPaths[orientation].loaded,
    transition: { type: 'spring', stiffness: 50 },
  },
  hovered: {
    webkitClipPath: clipPaths[orientation].hovered,
    clipPath: clipPaths[orientation].hovered,
    transition: { type: 'spring', stiffness: 70 },
  },
});


class ChooseImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoded: false,
      options: shuffle(this.props.data.options),
      x: 0,
      y: 0,
    };
  }

  // componentDidMount = () => {
  //   this.setState({
  //   });
  // }

  componentDidUpdate = (prevProps) => {
    if (this.props.start !== prevProps.start && this.props.start) {
      this.setState({
        isLoded: true,
        isHovered: false,
        options: shuffle(this.props.data.options),
      });
    }
  }

  render() {
    const { data, callback } = this.props;
    const { correct } = data;
    const { x, y } = this.state;

    return (
      <Form
        className="chooseimage"
        pose={this.state.isLoded ? 'loaded' : 'init'}
      >
        {this.state.options.map((option, index) => (
          <label
            key={index}
            htmlFor={`data-${index}-index-CI`}
            className="chooseimage__column"
            onClick={(e) => { this.setState({ x: e.pageX, y: e.pageY }); }}
            onPointerEnter={() => {
              this.setState({
                isHovered: option,
              });
            }}
            onPointerLeave={() => {
              this.setState({
                isHovered: false,
              });
            }}
          >
            <input
              id={`data-${index}-index-CI`}
              className="chooseimage__input visuallyhidden"
              type="radio"
              value={option}
              onChange={(e) => {
                callback(correct === option, e, { x, y });
              }}
            />
            <Image
              pose={
                this.state.isHovered === option
                  ? 'hovered'
                  : this.state.isLoded
                    ? 'loaded'
                    : 'init'
              }
              className="chooseimage__image"
            >
              <img
                src={`${process.env.PUBLIC_URL}/questions/images/${option}`}
                alt={`Bild ${index + 1}`}
                draggable="false"
              />
            </Image>
          </label>
        ))}
      </Form>
    );
  }
}
ChooseImage.displayName = 'ChooseImage';

export default ChooseImage;


ChooseImage.propTypes = {
  start: PropTypes.bool,
  data: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

ChooseImage.defaultProps = {
  start: false,
};
