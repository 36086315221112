import React, { Component } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import Heading from 'components/Heading';
import Smiley from 'components/Icons/Smiley';

// // uncomment if you wanna use the dev animation tool
// // For dev only:
// let MojsPlayer = {};
// if (process.env.NODE_ENV === 'development') {
//   MojsPlayer = require('mojs-player');
// }

import '../overlay.scss';

const OverlayBg = posed.div({
  init: {
    opacity: 0,
    bottom: ({ coordinates }) => (coordinates.bottom.from),
    top: ({ coordinates }) => (coordinates.top.from),
    left: ({ coordinates }) => (coordinates.left.from),
    right: ({ coordinates }) => (coordinates.right.from),
    borderRadius: '100000px',
    transition: {
      delay: 300,
      duration: 300,
    },
  },
  position: {
    scale: 1,
    opacity: 0,
    bottom: ({ coordinates }) => (coordinates.bottom.from),
    top: ({ coordinates }) => (coordinates.top.from),
    left: ({ coordinates }) => (coordinates.left.from),
    right: ({ coordinates }) => (coordinates.right.from),
    borderRadius: '100000px',
    transition: {
      duration: 10,
    },
  },
  start: {
    opacity: 1,
    bottom: ({ coordinates }) => (coordinates.bottom.to),
    top: ({ coordinates }) => (coordinates.top.to),
    left: ({ coordinates }) => (coordinates.left.to),
    right: ({ coordinates }) => (coordinates.right.to),
    borderRadius: '0',
    transition: {
      borderRadius: { ease: 'easeOut', duration: 200, delay: 100 },
      opacity: { ease: 'easeOut', duration: 50 },
      default: { ease: 'easeIn', duration: 300 },
    },
  },
});

const SvgWrapper = posed.div({
  init: {
    scale: 0,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  start: {
    scale: 1,
    transition: {
      type: 'spring',
      duration: 300,
      delay: 500,
      // ease: 'easeOut',
    },
  },
});

class OverlaySuccess extends Component {
  constructor(props) {
    super(props);
    this.overlayDom = React.createRef();
    this.state = {
      pose: 'init',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {

      window.soundeffects.play('correct');

      this.setState({ pose: 'position' });

      this.timout1 = setTimeout(() => {
        this.setState({ pose: 'start' });
      }, 10);

      this.timout2 = setTimeout(() => {
        this.setState({ pose: 'init' });
        this.props.callback('animCompleted');
      }, 2000);

    }
  }

  componentWillUnmount() {
    clearTimeout(this.timout1);
    clearTimeout(this.timout2);
  }

  render() {
    const height = window.innerHeight;
    const width = window.innerWidth;

    const size = (height > width) ? (height - width) / 2 : (width - height) / 2;
    const shape = (height > width) ? 'tall' : 'wide';

    const coordinates = {
      left: {
        from: (this.props.position.x)
          ? this.props.position.x - 20
          : 0,
        to: (shape === 'tall') ? -size : 0,
      },
      right: {
        from: (this.props.position.x)
          ? (width - this.props.position.x) - 20
          : 0,
        to: (shape === 'tall') ? -size : 0,
      },
      top: {
        from: (this.props.position.y)
          ? this.props.position.y - 20
          : 0,
        to: (shape === 'wide') ? -size : 0,
      },
      bottom: {
        from: (this.props.position.y)
          ? (height - this.props.position.y) - 20
          : 0,
        to: (shape === 'wide') ? -size : 0,
      },
    };

    return (
      <OverlayBg
        pose={this.state.pose}
        coordinates={coordinates}
        className="overlay overlay--answer overlay--success"
        ref={this.overlayDom}
      >
        <SvgWrapper
          className="content-wrapper"
          pose={this.props.show ? 'start' : 'init'}
        >
          {this.props.show && <Smiley type="happy" show={this.state.pose} />}
          <div>
            {this.props.show && (
              <Heading element="h1" className="h1" inverted>
                Rätt svar!
              </Heading>
            )}
          </div>
        </SvgWrapper>
      </OverlayBg>
    );
  }
}

OverlaySuccess.propTypes = {
  callback: PropTypes.func.isRequired,
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  position: PropTypes.object,

};

OverlaySuccess.defaultProps = {
  show: false,
  position: { x: 0, y: 0 },
};

export default OverlaySuccess;
