import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from 'modules/Header';
import Start from 'views/Start';
import Registration from 'views/Registration';
import Quiz from 'views/Quiz';
import Terms from 'views/Terms';
import Thanks from 'views/Thanks';

// const Start = lazy(() => import('views/Start'));
// const Quiz = lazy(() => import('views/Quiz'));
// const Terms = lazy(() => import('views/Terms'));

const App = () => {


  return (
    // <Suspense fallback={<div>laddar...</div>}>
    <>
      <Header />
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            component={Start}
          />
          <Route
            path="/quiz"
            component={Quiz}
            start
          />
          <Route
            exact
            path="/registrering"
            component={Registration}
            start
          />
          <Route
            path="/tack"
            component={Thanks}
            start
          />
          <Route
            path="/villkor"
            component={Terms}
            start
          />
          <Route path="*" component={Start} />
        </Switch>
      </Router>
    </>
    // </Suspense>
  );
};

export default App;
