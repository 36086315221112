/* eslint-disable max-len */
import React from 'react';
import posed from 'react-pose';
import PropTypes from 'prop-types';
import { tween } from 'popmotion';
import { interpolate } from 'flubber';
import './index.scss';

const morphTransition = (from, to, time) => tween({
  duration: time,
  flip: false,
}).pipe(interpolate(from, to, { maxSegmentLength: 2 }));

const smileyPath = {
  happy: {
    after: {
      rightEye: 'M190.7,114.8c8.5,0,16.3,3,22.4,8c2.8-4.2,4.4-9.2,4.4-14.6c0-14.8-12-26.8-26.8-26.8c-14.8,0-26.8,12-26.8,26.8c0,5.4,1.6,10.4,4.4,14.6C174.4,117.7,182.2,114.8,190.7,114.8z',
      leftEye: 'M92.6,114.8c8.5,0,16.3,3,22.4,8c2.8-4.2,4.4-9.2,4.4-14.6c0-14.8-12-26.8-26.8-26.8 c-14.8,0-26.8,12-26.8,26.8c0,5.4,1.6,10.4,4.4,14.6C76.3,117.7,84.1,114.8,92.6,114.8z',
      mouth: 'M141.9,202c24.4,0,44.2-19.8,44.2-44.2H97.7C97.7,182.2,117.5,202,141.9,202z',
    },
    before: {
      leftEye: 'M120.2,107.8c-12.9-0.4-19.3-1.2-26.6-1.2s-24.7,1.2-27.4,1.2 c-4.8,0,19.2-1.1,26.5-1.1S127.5,108.1,120.2,107.8z',
      oleftEye: 'M119.2,107.8c0,7.3-3,13.9-7.8,18.7c-4.8,4.8-11.4,7.8-18.7,7.8s-13.9-3-18.7-7.8 c-4.8-4.8-7.8-11.4-7.8-18.7s3-13.9,7.8-18.7c4.8-4.8,11.4-7.8,18.7-7.8s13.9,3,18.7,7.8C116.2,93.9,119.2,100.5,119.2,107.8z',
      rightEye: 'M217.1,109.4c-12.9-0.4-19.3-1.2-26.6-1.2c-7.3,0-24.7,1.2-27.4,1.2 c-4.8,0,19.2-1.1,26.5-1.1C196.9,108.4,224.4,109.6,217.1,109.4z',
      orightEye: 'M216.9,107.8c0,7.3-3,13.9-7.8,18.7c-4.8,4.8-11.4,7.8-18.7,7.8c-7.3,0-13.9-3-18.7-7.8 c-4.8-4.8-7.8-11.4-7.8-18.7s3-13.9,7.8-18.7c4.8-4.8,11.4-7.8,18.7-7.8c7.3,0,13.9,3,18.7,7.8C213.9,93.9,216.9,100.5,216.9,107.8z',
      mouth: 'M142.8,183.2c28.2,0,23.3,0,43.4,0H97.8C108.4,183.2,118.4,183.2,142.8,183.2z',
    },
  },
  sad: {
    after: {
      rightEye: 'M190.8,101.2c-8.5,0-16.3-3-22.4-8c-2.8,4.2-4.4,9.2-4.4,14.6c0,14.8,12,26.8,26.8,26.8s26.8-12,26.8-26.8c0-5.4-1.6-10.4-4.4-14.6C207.1,98.2,199.3,101.2,190.8,101.2z',
      leftEye: 'M92.6,101.2c-8.5,0-16.3-3-22.4-8c-2.8,4.2-4.4,9.2-4.4,14.6c0,14.8,12,26.8,26.8,26.8c14.8,0,26.8-12,26.8-26.8c0-5.4-1.6-10.4-4.4-14.6C109,98.2,101.2,101.2,92.6,101.2z',
      mouth: 'M142,169.7c-24.4,0-44.2,19.8-44.2,44.2h88.3C186.2,189.5,166.4,169.7,142,169.7z',
    },
    before: {
      leftEye: 'M120.2,107.8c-12.9-0.4-19.3-1.2-26.6-1.2s-24.7,1.2-27.4,1.2 c-4.8,0,19.2-1.1,26.5-1.1S127.5,108.1,120.2,107.8z',
      rightEye: 'M217.1,109.4c-12.9-0.4-19.3-1.2-26.6-1.2c-7.3,0-24.7,1.2-27.4,1.2 c-4.8,0,19.2-1.1,26.5-1.1C196.9,108.4,224.4,109.6,217.1,109.4z',
      mouth: 'M142.8,183.2c28.2,0,23.3,0,43.4,0H97.8C108.4,183.2,118.4,183.2,142.8,183.2z',
    },
  },
};

const RightEye = posed.path({
  init: {
    d: ({ paths }) => paths.before.rightEye,
    strokeWidth: 0,
  },
  show_face: {
    d: ({ paths }) => paths.before.rightEye,
    strokeWidth: 6,
  },
  animate: {
    d: ({ paths }) => paths.after.rightEye,
    transition: ({ paths }) => morphTransition(paths.before.rightEye, paths.after.rightEye, 150),
  },
});

const LeftEye = posed.path({
  init: {
    d: ({ paths }) => paths.before.leftEye,
    strokeWidth: 0,
  },
  show_face: {
    d: ({ paths }) => paths.before.leftEye,
    strokeWidth: 6,
  },
  animate: {
    d: ({ paths }) => paths.after.leftEye,
    transition: ({ paths }) => morphTransition(paths.before.leftEye, paths.after.leftEye, 150),
  },
});

const Mouth = posed.path({
  init: {
    d: ({ paths }) => paths.before.mouth,
    strokeWidth: 0,
  },
  show_face: {
    d: ({ paths }) => paths.before.mouth,
    strokeWidth: 6,
  },
  animate: {
    d: ({ paths }) => paths.after.mouth,
    transition: ({ paths }) => morphTransition(paths.before.mouth, paths.after.mouth, 250),
  },
});

const BigCircle = posed.circle({
  init: {
    opacity: 0,
    r: 0,
    strokeWidth: 100,
    transition: {
      duration: 1000,
      ease: 'easeIn',
    },
  },
  start: {
    opacity: 1,
    r: 142,
    strokeWidth: 6,
    transition: {
      type: 'spring',
      delay: 600,
    },
  },
});

const SmallCircle = posed.circle({
  init: {
    opacity: 0,
    r: 142,
    strokeWidth: 1,
    transition: {
      duration: 300,
      delay: 100,
      ease: 'easeIn',
    },
  },
  start: {
    opacity: 1,
    r: 122,
    strokeWidth: 3,
    transition: {
      type: 'spring',
      duration: 100,
      delay: 1100,
    },
  },
});

const Wrapper = posed.div({
  init: {
    scale: 0,
    transition: {
      duration: 300,
      ease: 'easeIn',
      delay: 100,
    },
  },
  start: {
    scale: 1,
    transition: {
      type: 'spring',
      duration: 300,
      delay: 500,
    },
  },
});

class Smiley extends React.Component {
  state = {
    pose: 'init',
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {

      this.timeout1 = setTimeout(() => {
        this.setState({ pose: 'show_face' });
      }, 1100);
      this.timeout2 = setTimeout(() => {
        this.setState({ pose: 'animate' });
      }, 1150);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout1);
    clearTimeout(this.timeout2);
  }

  render() {
    const animation = (this.props.show) ? 'init' : 'start';
    return (
      <Wrapper pose={animation} className="overlay-icon">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-50 -50 384 384"
          preserveAspectRatio="none"
          className="icon-smiley"
        // eslint-disable-next-line react/style-prop-object
        >
          <BigCircle
            pose={animation}
            cx="142.6"
            cy="141.8"
            className="line_small"
          />
          <RightEye pose={this.state.pose} paths={(this.props.type === 'happy') ? smileyPath.happy : smileyPath.sad} className="line" />
          <LeftEye pose={this.state.pose} paths={(this.props.type === 'happy') ? smileyPath.happy : smileyPath.sad} className="line" />
          <Mouth pose={this.state.pose} paths={(this.props.type === 'happy') ? smileyPath.happy : smileyPath.sad} className="line" />

          <SmallCircle
            pose={animation}
            cx="142.6"
            cy="141.8"
            className="line_small"
          />
        </svg>
      </Wrapper>
    );
  }
}

Smiley.propTypes = {
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  type: PropTypes.string,
};

Smiley.defaultProps = {
  show: false,
  type: 'happy',
};

export default Smiley;
