import React, { Component } from 'react';
import { shuffle } from 'helpers';
import posed from 'react-pose';
import PropTypes from 'prop-types';

import {
  spring,
  // transform
} from 'popmotion';

import sortIcon from '../../assets/sort-icon.svg';
import './index.scss';

const isLandscape = window.matchMedia('(orientation: landscape)').matches;

// const { interpolate } = transform;
// TODO: Animate a bit nicer

const Item = posed.div({
  draggable: 'x',
  // init: { scale: 1 },
  // init: {
  //   opacity: 1,
  //   scale: 1,
  // },
  drag: { scale: 1.1, rotate: 4 },
  // draggable: true,
  // dragBounds: isLandscape ? { top: -100, bottom: 100 } : { left: -100, right: 100 },

  dragEnd: {
    // x: 0,
    // y: 0,
    scale: 1,
    transition: spring,
    // scale: 1,
  },

  correct: {
    scale: 0.5,
    opacity: 0,
    // originY: '50%',
    // originX: '50%',
    transition: spring,
  },

  // passive: {
  //   opacity: ['x', interpolate(
  //     [-300, -200, 200, 300],
  //     [0, 1, 1, 0]
  //   )]
  // }
});

class Sort extends Component {
  constructor(props) {
    super(props);

    this.items = this.createItems(
      props.question.category1Name,
      props.question.category1,
      props.question.category2Name,
      props.question.category2,
    );

    // the distance the item need to travel for being correct;
    this.distance = isLandscape ? 60 : 20;

    const state = {
      leftActive: false,
      rightActive: false,
      mounted: false,
    };
    this.items.forEach((item) => { state[item.id] = false; });
    this.state = {
      ...state,
      sorted: 0,
      isFinish: false,
      idGameOver: false,
    };

    // set this flag to true when the user has answered false/ all right once
    this.hasCallbacked = false;
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }

  // Maybe is not neccesary
  // TODO: Reset if the same question happen to be displayed again
  // (or maybe just hide it from the dom when quiz is over)
  componentDidUpdate(prevProps) {
    if (this.props.question !== prevProps.question) {
      this.hasCallbacked = false; // reset the callback flag

      this.items = this.createItems(
        this.props.question.category1Name,
        this.props.question.category1,
        this.props.question.category2Name,
        this.props.question.category2,
      );
    }
  }

  constructItems = (name, items) => {
    const formatedItems = items.map((item, index) => (
      {
        category: name,
        title: item,
        id: name + index,
      }
    ));

    return formatedItems;
  }

  createItems = (category1Name, category1, category2Name, category2) => {
    const formatedCategory1 = this.constructItems(category1Name, category1);
    const formatedCategory2 = this.constructItems(category2Name, category2);
    const categories = shuffle(formatedCategory1.concat(formatedCategory2));
    return categories;
  }


  checkfCorrect = (item, toCategory, e) => {
    const { pageX, pageY } = e;

    if (this.hasCallbacked) return;
    if (!this.state[item.id]) {

      // Check if it is correct
      if (item.category === toCategory) {
        const sorted = this.state.sorted + 1;
        // Check if finish
        if (sorted === this.items.length) {
          this.hasCallbacked = true;
          this.props.callback(true, null, { x: pageX, y: pageY });
        }
        // Increase the sorted values
        this.setState({
          [item.id]: true,
          sorted,
        });
      } else {
        // Send game over callback
        this.hasCallbacked = true;
        this.props.callback(false, null, { x: pageX, y: pageY });
      }
    }
  }

  render() {
    const {
      category1Name,
      category2Name,
    } = this.props.question;

    // <div className={`sort__category sort__category--1 ${this.state.leftActive ? 'sort__category--active' : ''}`}> */}
    return (
      <div className="sort">
        <div className="sort__category sort__category--1">
          {category1Name}
        </div>
        <div className="sort__items">
          {
            this.items.map((item, index) => (
              <div key={`item${index}`} className={`sort__itemwrap ${index === this.items.length - 1 && this.props.start ? 'sort__itemwrap--tutorial' : ''}`}>
                <Item
                  pose={this.state[item.id] ? 'correct' : false}
                  key={item.id}
                  className={
                    `sort__item sort__item--${index} ${this.state[item.id]
                      ? 'sort__item--correct'
                      : ''
                    }`}
                  // Removed due to performence problems
                  // onValueChange={{
                  //   x: (x) => {
                  //     if (this.state.isDragging && x > this.distance) {
                  //       this.setState({
                  //         leftActive: false,
                  //         rightActive: true,
                  //       });
                  //     } else if (this.state.isDragging && x < -this.distance) {
                  //       this.setState({
                  //         leftActive: true,
                  //         rightActive: false,
                  //       });
                  //     } else {
                  //       this.setState({
                  //         leftActive: false,
                  //         rightActive: false,
                  //       });
                  //     }
                  //   },
                  // }}
                  // onDragStart={() => {
                  //   this.setState({
                  //     isDragging: true,
                  //   });
                  // }}
                  onDragEnd={(e) => {

                    const x = (e.pageX) ? e.pageX : e.changedTouches[0].pageX;

                    if (x < window.innerWidth / 2 - this.distance) {
                      // Left
                      this.checkfCorrect(item, category1Name, e);
                    }
                    if (x > window.innerWidth / 2 + this.distance) {
                      // Right
                      this.checkfCorrect(item, category2Name, e);
                    }

                    // Diabled due to performence problems
                    // this.setState({
                    //   leftActive: false,
                    //   rightActive: false,
                    //   isDragging: false,
                    // });

                    // else {
                    // card dropped inside "safe area" (this.distance)
                    // }
                  }}

                >
                  <h3>{item.title}</h3>
                </Item>
              </div>
            ))
          }
          <div className="sort__icon">
            <img src={`${sortIcon}`} alt="Dra och släpp" />
          </div>
        </div>
        <div className="sort__category sort__category--2">
          {category2Name}
        </div>
      </div>
    );
    // <div className={`sort__category sort__category--2 ${this.state.rightActive ? 'sort__category--active' : ''}`}>
  }
}

export default Sort;

Sort.propTypes = {
  question: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  start: PropTypes.bool.isRequired,
};

Sort.displayName = 'Sort';
