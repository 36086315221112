import React, { Component } from 'react';
import { shuffle } from 'helpers';
import PropTypes from 'prop-types';
import './index.scss';

export default class Pair extends Component {

  constructor(props) {
    super(props);
    this.state = {
      prevSelected: false,
      sortedData: this.sortData(),
      matched: [],
      x: 0,
      y: 0,
    };
  }

  pairSorting = (pair) => {

    if (!pair) return [];

    return [
      {
        id: pair[0],
        match: pair[1],
      },
      {
        id: pair[1],
        match: pair[0],
      },
    ];
  }

  sortData = () => {
    const { pair1, pair2, pair3 } = this.props.data;

    const sortedPair = [
      ...this.pairSorting(pair1),
      ...this.pairSorting(pair2),
      ...this.pairSorting(pair3),
    ];

    return shuffle(sortedPair);
  }

  handleSelect = (value, match) => {
    const { x, y } = this.state;

    if (this.state.prevSelected) {
      if (match === this.state.prevSelected) {
        const matched = this.state.matched;
        matched.push(value, match);

        // If length of options and matched is the same is finished
        if (this.state.sortedData.length === matched.length) {
          this.props.callback(true, null, { x, y });
        }

        this.setState({
          prevSelected: false,
          matched,
        });
      } else {
        this.props.callback(false, null, { x, y });
      }
    } else {
      this.setState({
        prevSelected: value,
      });
    }
  }

  render() {

    const { sortedData } = this.state;

    return (
      <form className="pair block-width-medium">
        {sortedData.map((data, index) => (
          <div key={index} className="pair__option">
            <label
              onClick={(e) => { this.setState({ x: e.pageX, y: e.pageY }); }}
              htmlFor={`${data.id}-${index}-PR`}
              className={`pair__option__button ${
                (this.state.prevSelected === data.id || this.state.matched.includes(data.id)) ? 'selected' : ''}`}
            >
              <input
                id={`${data.id}-${index}-PR`}
                className="visuallyhidden"
                type="checkbox"
                disabled={data.id === this.state.prevSelected
                  || this.state.matched.includes(data.id)}
                checked={this.state.prevSelected === data.id}
                onChange={(e) => {
                  e.preventDefault();
                  this.handleSelect(data.id, data.match);
                }}
              />
              <div className="text">
                <strong>{data.id}</strong>
                <svg
                  className={`svg-checked ${this.state.matched.includes(data.id) ? 'matched' : ''}`}
                  width="19"
                  height="13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1 5l6 7L17.5 1" stroke="#FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

              </div>
            </label>
          </div>
        ))}
      </form>
    );
  }
}

Pair.propTypes = {
  callback: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};
