import React from 'react';
import Heading from 'components/Heading';
import Button from 'components/Button';
// import Footer from 'components/Footer';
import './index.scss';

const Thanks = () => (
  <div className="wrapper-content">
    <div className="block-width-medium c-neutral-5 s-top-l">
      <Heading element="h1" className="h1">Tack!</Heading>
      <Heading element="h2" className="h2">
        Du är nu med i utlottningen.
      </Heading>
      <p className="endscreen__message signed c-neutral-5">
        Vinnaren kommer bli kontaktad via mail.
        Under tiden får du mer än gärna slipa på ditt
        resultat eller läsa mer om oss på vår webbplats.
      </p>

      <div className="endscreen__button-row signed s-bottom-l">
        <Button href="/quiz">
          Spela igen!
        </Button>

        <Button href="https://www.cowi.se" color="white">
          cowi.se
        </Button>
        {/* <Footer relative /> */}
      </div>
    </div>
  </div>
);

export default Thanks;
