import React from 'react';
import './index.scss';
import PropTypes from 'prop-types';

const Heading = ({
  children, element, className, inverted,
}) => {
  const HeadingLevel = element || 'h1';
  const classModifier = className || element;

  return (
    <div className={`heading-background ${inverted ? 'inverted' : ''}`}>
      <HeadingLevel className={`${classModifier}`}>
        <span>{children}</span>
      </HeadingLevel>
    </div>
  );
};

Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  element: PropTypes.string,
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

Heading.defaultProps = {
  element: 'h1',
  className: '',
  inverted: false,
};

export default Heading;
