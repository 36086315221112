import React, { Component } from 'react';
import posed from 'react-pose';
import Footer from 'components/Footer';
import PropTypes from 'prop-types';
import Heading from 'components/Heading';
import price from 'assets/price.png';
import Button from 'components/Button';
import { Redirect } from 'react-router-dom';

import './index.scss';

const Screen = posed.div({
  open: {
    opacity: 1,
    applyAtEnd: { pointerEvents: 'all' },
  },
  closed: {
    opacity: 0,
    applyAtEnd: { pointerEvents: 'none' },
  },
});

const Content = posed.div({
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 100, delay: 1000 },
  },
  init: {
    opacity: 0,
    y: 50,
    transition: { type: 'spring', stiffness: 100, delay: 1000 },
  },
});

class Endscreen extends Component {
  state = {
    show: false,
    register: false,
  }

  componentDidMount() {

    this.savePoints();

    if (this.props.show) {
      // this.timeOut = setTimeout(() => {
      (this.props.points >= this.props.pointsNeeded) ? window.soundeffects.play('endgamesuccess') : window.soundeffects.play('endgamefail');
      this.setState({ show: true });
      // }, 1);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      // this.timeOut = setTimeout(() => {
      (this.props.points >= this.props.pointsNeeded) ? window.soundeffects.play('endgamesuccess') : window.soundeffects.play('endgamefail');

      this.setState({ show: true });
      // }, 1);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  savePoints = () => {
    const result = {
      succeeded: this.props.points >= this.props.pointsNeeded,
      points: this.props.points,
    };
    window.localStorage.setItem('result', JSON.stringify(result));
  }


  render() {
    const { points, pointsNeeded, numberOfQuestions } = this.props;
    const { register } = this.state;

    if (register) {
      return (
        <Redirect
          push
          to={{
            pathname: '/registrering',
            // eslint-disable-next-line react/prop-types
            state: { from: this.props.location },
          }}
        />
      );
    }

    return (
      <div className="block-width-large">
        <Screen
          pose={this.props.show ? 'open' : 'closed'}
          onPoseComplete={this.props.onPoseComplete}
          className="endscreen"
        >
          <>
            <Content pose={this.state.show ? 'visible' : 'init'} className="endscreen__restart">

              <Heading element="h1" className="h1">{points >= pointsNeeded ? 'Grattis!' : 'Nästan där!'}</Heading>
              <Heading element="h2" className="h2">
                {points >= pointsNeeded
                  ? 'Du kan nu delta i vår utlottning'
                  : `För att delta i vår utlottning behöver du minst ${pointsNeeded} rätta svar.`}
              </Heading>
              <Heading element="h2" className="h2">{`Du fick ${points} / ${numberOfQuestions} rätt`}</Heading>
              <div className="endscreen__image">
                <img src={price} alt="Stora Lisebergs express pass" />
              </div>
              <p className="endscreen__message c-neutral-5 center">
                {points >= pointsNeeded
                  ? (
                    'Klicka på delta i utlottningen för att ha chansen att vinna priset på bilden. Om du väljer att delta kommer vi höra av oss till den email du skrev upp dig med i börjat av spelet.'
                  )
                  : (
                    'Om du klarar alla frågor får du chansen att vara med i utlottningen av priset som syns på bilden.'
                  )}
              </p>


              <div className="endscreen__button-row s-bottom-l">
                {(points >= pointsNeeded) && (
                  <Button href="/registrering">
                    Delta i utlottningen
                  </Button>
                )}
                <Button href="/quiz" color={points < pointsNeeded ? '' : 'white'}>
                  Spela igen!
                </Button>
              </div>


            </Content>
          </>
          <Footer relative />
        </Screen>
      </div>
    );
  }
}

Endscreen.propTypes = {
  onPoseComplete: PropTypes.func,
  show: PropTypes.bool,
  points: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  numberOfQuestions: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  pointsNeeded: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
};

Endscreen.defaultProps = {
  show: false,
  onPoseComplete: () => { },
  points: 0,
  pointsNeeded: 0,
  numberOfQuestions: 0,
};

export default Endscreen;
