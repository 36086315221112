import { handleOverlay } from '../state/actions';

export const handleRouteWithOverlay = ({
  dispatch, route, history, overlay,
}) => {
  dispatch(handleOverlay({ ...overlay, show: true }));
  setTimeout(() => {
    history.push(route);
  }, 500);
};

export const handleHideOverlay = ({ dispatch, hideTime }) => {
  setTimeout(() => {
    dispatch(handleOverlay({ show: false, variant: null, time: false }));
  }, hideTime);
};

export const shuffle = (a) => {
  const newValue = a;
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return newValue;
};

export const filterQuestions = (questions, level, theme, amount, randomize) => {
  const sortedQuestions = (randomize) ? shuffle(questions) : questions;
  const questionTypes = ['multichoice', 'sort', 'pair', 'yesno', 'chooseimage'];
  const amountOfType = Math.floor(amount / questionTypes.length);

  const differentQuestionByType = questionTypes.map((type, index) => {
    const sorted = sortedQuestions.filter(x => x.type === type);

    if (sorted.length > amountOfType) {
      return sorted.slice(0, amountOfType);
    }
    sortedQuestions.slice(index, index + 1);
    return sorted;
  }).flat();

  return differentQuestionByType.concat(
    sortedQuestions.filter(question => (
      differentQuestionByType.indexOf(question) < 0
    )),
  ).slice(0, amount);
};

// Returns a reading time based on the letters in the string
export const countReadingTime = (title) => {
  const titleLength = title.length;

  const time = 350 // human reaction time /350)
    + (titleLength * 50); // x ms per letter

  return time;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min)) + min;
};

// Get random value from array
export const sample = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

// Calculates wich period the quiz is in and return 1 || 2 || 3 based on wich period it is.
// 1 = goalkeeper
// 2 = skates
// 3 = stick
export const getPeriod = () => {
  if (new Date() >= new Date('2019-08-05')) {
    return 3;
  } if (new Date() >= new Date('2019-06-28')) {
    return 2;
  }
  return 1;

};

export const getReggedTierKey = () => {
  // Set key based on period
  // If period 1: regged_tier (for backwards compatability)
  // Period 2: regged_tier_2,  Period 2: regged_tier_3,
  const period = getPeriod();
  switch (period) {
  case 3:
    return 'regged_tier_3';
  case 2:
    return 'regged_tier_2';
  case 1:
  default:
    return 'regged_tier';
  }
};
