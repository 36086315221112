// Get new data:
/*
The data for all questions in on google drive on [this link](https://docs.google.com/spreadsheets/d/1N8IQS4MGDQXmcEvxyfpdT5VplbapgmFZfVbJ7O4P34s/edit?usp=sharing)
You can export it to JSON using a JSON exporter plugin that is in the file
(Plugins/Export Sheet Data)
Remove all wrapper objects so everything become a big long juicy array and replace this one 👇
*/
export default [
  {
    'type': 'sort',
    'title': 'Är COWI experter inom följande områden?',
    'time': '25',
    'category1Name': 'JA',
    'category1': [
      'Infrastruktur',
      'Stadsutveckling',
      'Byggnader',
      'Industri och energi',
      'Vatten och miljö',
      'BIM & digitala processer',
    ],
    'category2Name': 'NEJ',
    'category2': [
      'Biltillverkning',
      'Sjöfart',
      'Livsmedelsproduktion',
      'Reklam',
      'Finans',
    ],
  },
  {
    'type': 'sort',
    'title': 'Vad är sant gällande ICEHOTEL365?',
    'time': '25',
    'category1Name': 'SANT',
    'category1': [
      'Det har minusgrader inomhus året runt',
      'Det kyls ner med hjälp av solen',
    ],
    'category2Name': 'FALSKT',
    'category2': [
      'Det smälter ner varje år',
      'Det ligger i Kiruna',
    ],
  },
  {
    'type': 'sort',
    'title': 'Vad är sant om världens längsta sänktunnel för biltrafik?',
    'time': '25',
    'category1Name': 'SANT',
    'category1': [
      'Den ligger i Kina',
      'Den är 5,6 km lång',
      'Den består av 30 tunnelelement',
      'Den är gjord av vattentät betong',
    ],
    'category2Name': 'FALSKT',
    'category2': [
      'Den ligger i USA',
      'Den är 15 mil lång',
      'Den finansierades av EU',
    ],
  },
  {
    'type': 'sort',
    'title': 'Vad är sant om Fornebubanen?',
    'time': '25',
    'category1Name': 'SANT',
    'category1': [
      'Förlänger Oslos tunnelbana',
      'Norges största infrastrukturprojekt på 20 år',
      '8,5 km lång',
    ],
    'category2Name': 'FALSKT',
    'category2': [
      'En ringled för elbilar',
      'En linbana över stadskärnan',
      'Ligger i Stavanger',
    ],
  },
  {
    'type': 'sort',
    'title': 'Vad är sant om det nya området Brunnshög utanför Lund?',
    'time': '25',
    'category1Name': 'SANT',
    'category1': [
      'Värms upp med överskottsvärme',
      'Upp till 40 000 personer ska bo och arbeta där',
      'Det ska gå spårväg dit',
    ],
    'category2Name': 'FALSKT',
    'category2': [
      'Självförsörjande på livsmedel',
      'Det bor bara danskar där',
      'Trump vill köpa området',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Vad står namnet COWI för?',
    'time': '8',
    'correct': 'Christen Ostenfeld och Wriborg Iønson',
    'options': [
      'COnsultant WIsdom',
      'Civil engineer on Work Internationally',
      'Christen Ostenfeld och Wriborg Iønson',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Hur många pågående projekt har COWI vanligtvis?',
    'time': '8',
    'correct': '14 000',
    'options': [
      '900',
      '20 000',
      '14 000',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Hur många COWI-kontor finns det i världen?',
    'time': '8',
    'correct': '100',
    'options': [
      '50',
      '100',
      '150',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Vad kallar COWI sin helhetssyn?',
    'time': '8',
    'correct': '360-graderslösningar',
    'options': [
      'Morgondagens lösningar',
      'Kunskapslösningar',
      '360-graderslösningar',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Vad är COWIs dotterbolag AEC specialister inom?',
    'time': '8',
    'correct': 'CAD-, BIM- och IT-lösningar',
    'options': [
      'CAD-, BIM- och IT-lösningar',
      'Arkitektur',
      'Processteknik',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Vad står CCS för?',
    'time': '8',
    'correct': 'Carbon Capture Storage',
    'options': [
      'Carbon Capture Storage',
      'Climate Crisis System',
      'Computer Consultant Services',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'COWI bidrar till att kemiklustret i Stenungsund ska bli fossilfria. Till vilket år?',
    'time': '8',
    'correct': '2030',
    'options': [
      '2020',
      '2030',
      '2040',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Vad krävs för att få medel ur COWI-fonden?',
    'time': '8',
    'correct': 'Projekt/initiativ som bidrar till ett långsiktigt och hållbart samhälle',
    'options': [
      'Projekt/initiativ som bidrar till ett långsiktigt och hållbart samhälle',
      'Höga betyg',
      'Rekommendation från forskningsinstitut',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Vad sa COWIs expert Ian Firth i sitt TED-talk?',
    'time': '8',
    'correct': 'Broar ska vara vackra',
    'options': [
      'Broar ska vara vackra',
      'Hus ska vara höga',
      'Vägar ska vara raka',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Var ligger den första gastankstationen för tunga fordon i Sverige?',
    'time': '10',
    'correct': 'Västerås',
    'options': [
      'Västerås',
      'Borås',
      'Alingsås',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'I vilket land byggde COWI en bro tillsammans med välgörenhetsorganisationen Bridges to Prosperity?',
    'time': '10',
    'correct': 'Rwanda',
    'options': [
      'Rwanda',
      'Botswana',
      'Uganda',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Hur många ton avfall per dag ska COWI hjälpa Egypten hantera på ett hållbart sätt?',
    'time': '10',
    'correct': '5000',
    'options': [
      '3000',
      '5000',
      '1000',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Vad heter den lösning för automatisk analys av trafikflöden COWI använder?',
    'time': '8',
    'correct': 'Data from Sky',
    'options': [
      'Data from Sky',
      'Data from Water',
      'Data from Ground',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'Var ligger sjukhuset som har utsetts till världens bästa digitala byggprojekt?',
    'time': '10',
    'correct': 'Stavanger',
    'options': [
      'Uppsala',
      'Århus',
      'Stavanger',
    ],
  },
  {
    'type': 'multichoice',
    'title': 'COWIs koncernchef är ordförande i Dansk Industri. Vad heter han?',
    'time': '8',
    'correct': 'Lars-Peter Søbye',
    'options': [
      'Karl-Jonas Jønsen',
      'Lars-Peter Søbye',
      'Knud-Henrik Næss',
    ],
  },
  {
    'type': 'yesno',
    'title': 'Är COWI stiftelse- och personalägt?',
    'time': '6',
    'isCorrect': true,
  },
  {
    'type': 'yesno',
    'title': 'Ingår Arkitema, ett av Nordens största Arkitektkontor, i COWI?',
    'time': '6',
    'isCorrect': true,
  },
  {
    'type': 'yesno',
    'title': 'Kan COWI certifiera byggnader inom BREEAM, WELL, LEED och Miljöbyggnad?',
    'time': '6',
    'isCorrect': true,
  },
  {
    'type': 'chooseimage',
    'title': 'Vilken av byggnaderna har belönats med stålbyggnadspris?',
    'time': '8',
    'correct': 'aula_medica.jpg',
    'options': [
      'aula_medica.jpg',
      'icehotel.jpg',
      'lidl.jpg',
      'emporia.jpg',
    ],
  },
  {
    'type': 'chooseimage',
    'title': 'På vilket nöjesfält finns attraktionen Valkyria?',
    'time': '8',
    'correct': 'liseberg.jpg',
    'options': [
      'liseberg.jpg',
      'tivoli.jpg',
      'grona_lund.jpg',
      'disneyland.jpg',
    ],
  },
  {
    'type': 'chooseimage',
    'title': 'En av världens längsta hängbroar finns i Turkiet. Hur ser den ut?',
    'time': '8',
    'correct': 'osman_gazi.jpg',
    'options': [
      'great_belt_bridge.jpg',
      'halogaland_bridge.jpg',
      'oresund_bridge.jpg',
      'osman_gazi.jpg',
    ],
  },
  {
    'type': 'chooseimage',
    'title': 'Vilket projekt blev 2018 nominerat till Årets bygge?',
    'time': '8',
    'correct': 'nationalmuseum.jpg',
    'options': [
      'kungliga_operan.jpg',
      'nationalmuseum.jpg',
      'parkeringshus_danderyd.jpg',
      'ostermalms_saluhall.jpg',
    ],
  },
  {
    'type': 'chooseimage',
    'title': 'Vilken råvara kan göra flyget klimatsmart?',
    'time': '8',
    'correct': 'skog.jpg',
    'options': [
      'skog.jpg',
      'sjogras.jpg',
      'raps.jpg',
      'rag.jpg',
    ],
  },
  {
    'type': 'pair',
    'title': 'Para ihop två och två',
    'time': '12',
    'pair1': [
      'Ostlänken',
      'Tåg',
    ],
    'pair2': [
      'Mall of Scandinavia',
      'Köpcentrum',
    ],
    'pair3': [
      'European Spallation Source',
      'Forskningsanläggning',
    ],
  },
  {
    'type': 'pair',
    'title': 'Para ihop två och två',
    'time': '12',
    'pair1': [
      'Masthuggskajen',
      'Ny konstgjord halvö i Göteborg',
    ],
    'pair2': [
      'Preem',
      'Förnybar diesel från skogen',
    ],
    'pair3': [
      'Lidl',
      'Världens grönaste butik',
    ],
  },
];
