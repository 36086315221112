/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import Heading from 'components/Heading';
import Button from 'components/Button';
import ChallengeLogo from 'components/ChallengeLogo';
import posed from 'react-pose';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './index.scss';

const LogoWrapper = posed.div({
  init: {
    opacity: 0,
    scale: 1.1,
    y: 0,
  },
  enter: {
    opacity: 1,
    scale: 1,
    y: ({ logoEnterY }) => logoEnterY,
    transition: {
      opacity: { ease: 'easeOut', duration: 300 },
      scale: { ease: 'easeOut', duration: 300 },
      default: { ease: 'linear', duration: 0 },
    },
  },
  finish: {
    y: 0,
    transition: {
      default: { ease: 'backIn', duration: 400 },
    },
  },
});

const HeadingWrapper = posed.div({
  init: {
    opacity: 0,
    x: 100,
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 300,
      duration: 300,
    },
  },
});

const FormWrapper = posed.div({
  init: {
    opacity: 0,
    x: 100,
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 400,
      duration: 200,
    },
  },
});

class Start extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogo: false,
      logoYStart: 0,
      finishAnimation: false,
    };

    this.logo = React.createRef();
    this.logoEnterY = 400;
  }

  componentDidMount() {
    const elem = this.logo.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const calculatedY = (viewportHeight / 2) - elem.y - (elem.height / 2);

    this.timeout1 = setTimeout(() => {
      this.setState({
        showLogo: true,
        logoYStart: calculatedY,
      });
    }, 500);

    this.timeout2 = setTimeout(() => {
      this.setState({
        finishAnimation: true,
      });
    }, 1900);
  }

  componentDidUpdate = () => {
    if (this.props.history.location.pathname !== '/') {
      this.props.history.replace('/');
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout1);
    clearTimeout(this.timeout2);
  }

  render() {
    return (
      <main className="view-start">
        <div className="wrapper-content">
          <div className="block-width-medium">
            <LogoWrapper
              pose={this.state.finishAnimation ? 'finish'
                : this.state.showLogo
                  ? 'enter'
                  : 'init'
              }
              logoEnterY={this.state.logoYStart}
            >
              <div className="view-start__logo" ref={this.logo}>
                <ChallengeLogo />
              </div>
            </LogoWrapper>
            <HeadingWrapper
              pose={this.state.finishAnimation ? 'visible' : 'init'}
            >
              <Heading element="h2" className="h2">
                Mät din kunskap och få chansen att vinna fina priser!
              </Heading>
            </HeadingWrapper>
            <FormWrapper pose={this.state.finishAnimation ? 'visible' : 'init'}>
              <div className="center s-top-lg">
                <Button href="/quiz">
                  Spela nu!
                </Button>
              </div>
              <div className="center c-neutral-5 view-start__url">
                challenge.cowi.se
              </div>
            </FormWrapper>
          </div>
        </div>
      </main>
    );
  }
}

Start.propTypes = {
  history: PropTypes.func.isRequired,
};

export default withRouter(Start);
