import React, { Component } from 'react';
// import CreateQuiz from 'modules/CreateQuiz';
import CreateQuiz from 'modules/CreateQuiz/debug'; // If you wanna list all questions
import Endscreen from 'modules/Endscreen';
import { filterQuestions, shuffle } from 'helpers';
import ErrorBoundary from 'components/ErrorBoundary';
import OverlayGo from 'modules/OverlayGo';

// Settings
const amount = 15; // the number of questions per game
let pointsNeeded = 7; // the number of questions the player need to succed in to be able join the send it their email

// The qestions data
let questionsData = require('../../data/data').default;

if (process.env.NODE_ENV === 'development') {
  pointsNeeded = 2;
  /* eslint-disable */
  // Show debug data if in development mode
  questionsData = require('../../data/debugData').default;
  // questionsData = require('../../data/data').default;
  // Uncomment these if you wanna try a specific question type
  // questionsData = require('../../data/debugData_chooseimage').default;
  // questionsData = require('../../data/debugData_multichoice').default;
  // questionsData = require('../../data/debugData_pair').default;
  // questionsData = require('../../data/debugData_sort').default;
  // questionsData = require('../../data/debugData_yesno').default;
  /* eslint-enable */
}

// const questionsData = require('../../data/debugData').default;
// const questionsData = require('../../data/debugData').default;


class Quiz extends Component {
  constructor(props) {
    super(props);

    // // For debug endscreen: (http://localhost:3000/quiz)
    // this.state = {
    //   finished: true,
    //   points: 4,
    //   numberOfQuestions: 4,
    //   animCompleted: false,
    //   go: false,
    // };
    this.state = {
      finished: false,
      // complete: false,
      points: false,
      numberOfQuestions: false,
      animCompleted: false,
      go: false,
    };
  }

  // componentDidMount = () => {
  //   this.props.hideOverlay();
  // }

  componentDidMount() {
    this.setState({
      go: true,
    });
  }

  onQuizComplete = (points, numberOfQuestions) => {
    const newState = {
      complete: false,
      finished: true,
      points,
      numberOfQuestions,
    };
    if (points === numberOfQuestions) {
      newState.complete = true;
    }

    this.setState(newState);
  }

  handleOverlays = (msg) => {
    if (msg === 'animCompleted') {
      this.setState({
        go: false,
        animCompleted: true,
      });
    }
  }

  render() {
    const randomize = true;
    const questions = (!randomize) ? filterQuestions(questionsData, 1, 'general', amount, false) : shuffle(filterQuestions(questionsData, 1, 'general', amount, true));

    const { points, numberOfQuestions, finished } = this.state;

    return (
      <>
        <ErrorBoundary>
          {!finished
            && (
              <CreateQuiz
                questions={questions}
                animCompleted={this.state.animCompleted}
                onComplete={this.onQuizComplete}
              />
            )
          }
          <OverlayGo
            show={this.state.go}
            callback={this.handleOverlays}
            height={this.state.height}
            width={this.state.width}
          />
          {finished
            && (
              <Endscreen
                show={finished}
                points={points}
                numberOfQuestions={numberOfQuestions}
                pointsNeeded={pointsNeeded}
              />
            )
          }
        </ErrorBoundary>
      </>
    );
  }
}

export default Quiz;
