import React, { Component } from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';
import Heading from 'components/Heading';

// // uncomment if you wanna use the dev animation tool
// // For dev only:
// let MojsPlayer = {};
// if (process.env.NODE_ENV === 'development') {
//   MojsPlayer = require('mojs-player');
// }

import '../overlay.scss';

const OverlayWhite = posed.div({
  init: {
    opacity: 0,
    transition: {
      duration: 300,
    },
  },
  start: {
    opacity: 1,
    transition: {
      duration: 0,
    },
  },
});

const OverlayBg = posed.div({
  init: {
    opacity: 0,
    transition: {
      delay: 300,
      duration: 300,
    },
  },
  start: {
    opacity: 1,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
});

const SvgWrapper = posed.div({
  init: {
    scale: 0,
    transition: {
      duration: 300,
      ease: 'easeIn',
    },
  },
  start: {
    scale: 1,
    transition: {
      type: 'spring',
      duration: 300,
      delay: 400,
      // ease: 'easeOut',
    },
  },
});

class OverlayWrong extends Component {
  constructor(props) {
    super(props);
    this.overlayDom = React.createRef();
    // this.state = {
    //   show: false,
    // };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {

      window.soundeffects.play('start');

      // this.setState({ show: true });

      this.timeout1 = setTimeout(() => {
        this.props.callback('animCompleted');
      }, 2000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout1);
  }

  render() {
    return (
      <>
        <OverlayWhite pose={this.props.show ? 'start' : 'init'} className="overlay overlay--white" />
        <OverlayBg pose={this.props.show ? 'start' : 'init'} className="overlay overlay--answer" ref={this.overlayDom}>
          <SvgWrapper className="content-wrapper" pose={this.props.show ? 'start' : 'init'}>
            <Heading element="h1" className="h1" inverted>
              Kör!
            </Heading>
          </SvgWrapper>
        </OverlayBg>
      </>
    );
  }
}

OverlayWrong.propTypes = {
  callback: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

OverlayWrong.defaultProps = {
  show: false,
};

export default OverlayWrong;
