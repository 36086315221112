import React from 'react';
import Heading from 'components/Heading';
import Button from 'components/Button';
import './index.scss';

const Terms = () => (
  <div className="wrapper-content">
    <div className="block-width-medium c-neutral-5 s-top-l">
      <Heading element="h1" className="h2">Användarvillkor</Heading>
      <p>Lorem ipsum placeholder text. Larger Works.
        You may also choose to offer, and to permit linking proprietary
        applications with the distribution. The name Zope Corporation (tm)
        must not be used according to the author/donor to decide if he or she
        is willing to distribute the Covered Code and/or Modifications of the
        License, or (at your option) any later version. The Current Maintainer
        must become or remain the Current Maintainer to take away your freedom
        to share and change it.
      </p>

      <h2 className="subheading">Subheading</h2>
      <p>By contrast, our general public to re-distribute and re-use
        their contributions freely, as long as the conditions of
        this License may be published from time to time.
        No one other than You. Work means a work containing the Program under
        their own license agreement (Agreement).
      </p>
      <p>By contrast, our general public to re-distribute and re-use
        their contributions freely, as long as the conditions of
        this License may be published from time to time.
        No one other than You. Work means a work containing the Program under
        their own license agreement (Agreement).
      </p>

      <div className="center s-bottom-l">
        <Button route="/registrering">Tillbaka</Button>
      </div>
    </div>
  </div>
);

export default Terms;
