import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './index.scss';

class Button extends PureComponent {
  render() {
    const {
      className,
      children,
      href,
      color,
      route,
      ...props
    } = this.props;

    if (href) {
      return (
        <a
          href={href}
          className={`button ${className} ${color ? `button--${color}` : ''}`}
          {...props}
        >
          {children}
        </a>
      );
    }
    if (route) {
      return (
        <Link to={route}>
          <button
            type="button"
            className={`button ${className} ${color ? `button--${color}` : ''}`}
            {...props}
          >
            {children}
          </button>
        </Link>
      );
    }
    return (
      <button type="button" className={`button ${className}`} {...props}>
        {children}
      </button>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  href: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  color: PropTypes.string,
  route: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

Button.defaultProps = {
  className: '',
  href: false,
  color: '',
  route: false,
};

export default Button;
