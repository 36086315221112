/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import Heading from 'components/Heading';
import EmailSign from 'components/EmailSign';
import ChallengeLogo from 'components/ChallengeLogo';
import posed from 'react-pose';
// import PropTypes from 'prop-types';
// import { withRouter } from 'react-router-dom';
import './index.scss';

const LogoWrapper = posed.div({
  init: {
    opacity: 0,
    scale: 1.1,
    y: 0,
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: ({ logoEnterY }) => logoEnterY,
    transition: {
      opacity: { ease: 'easeOut', duration: 300 },
      scale: { ease: 'easeOut', duration: 300 },
      default: { ease: 'linear', duration: 0 },
    },
  },
  finish: {
    y: 0,
    transition: {
      default: { ease: 'backIn', duration: 400 },
    },
  },
});

const HeadingWrapper = posed.div({
  init: {
    opacity: 0,
    x: 100,
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 300,
      duration: 300,
    },
  },
});

const FormWrapper = posed.div({
  init: {
    opacity: 0,
    x: 100,
    transition: {
      duration: 300,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 400,
      duration: 200,
    },
  },
});

class Registration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      finishAnimation: false,
    };

    this.logo = React.createRef();
    this.logoEnterY = 400;
  }

  componentDidMount() {
    this.setState({
      finishAnimation: true,
    });
  }

  // componentDidUpdate = () => {
  //   if (this.props.history.location.pathname !== '/') {
  //     this.props.history.replace('/');
  //   }
  // }

  render() {
    return (
      <main className="view-registration">
        <div className="wrapper-content">
          <div className="block-width-medium">
            <LogoWrapper
              pose={this.state.finishAnimation ? 'visible' : 'init'}
            >
              <div className="view-registration__logo" ref={this.logo}>
                <ChallengeLogo />
              </div>
            </LogoWrapper>
            <HeadingWrapper
              pose={this.state.finishAnimation ? 'visible' : 'init'}
            >
              <Heading element="h2" className="h2">
                Fyll i dina uppgifter för
                att vara med och tävla!
              </Heading>
            </HeadingWrapper>
            <FormWrapper pose={this.state.finishAnimation ? 'visible' : 'init'}>
              <EmailSign />
              <div className="center c-neutral-5 view-registration__url">
                challenge.cowi.se
              </div>
            </FormWrapper>
          </div>
        </div>
      </main>
    );
  }
}

// Registration.propTypes = {
//   history: PropTypes.func.isRequired,
// };

export default Registration;
