/* eslint-disable */
import React, { Component, Fragment } from 'react';
import ChooseImage from 'components/ChooseImage';
import Sort from 'components/Sort';
import Pair from 'components/Pair';
import MultiChoice from 'components/MultiChoice';
import Endscreen from 'modules/Endscreen';
import OverlayWrong from 'modules/OverlayWrong';
import OverlaySuccess from 'modules/OverlaySuccess';
import YesNo from 'components/YesNo';
import { shuffle } from 'helpers';
import './index.scss';
import { spring } from 'popmotion';
import Footer from 'components/Footer';

import './debug.scss';
// import { spring } from 'popmotion';

// The qestions data
let questionsData = require('../../data/data').default;

if (process.env.NODE_ENV === 'development') {
  /* eslint-disable */
  // Show debug data if in development mode
  // questionsData = require('../../data/debugData').default;
  questionsData = require('../../data/data').default;
  // Uncomment these if you wanna try a specific question type
  // questionsData = require('../../data/debugData_chooseimage').default;
  // questionsData = require('../../data/debugData_multichoice').default;
  // questionsData = require('../../data/debugData_pair').default;
  // questionsData = require('../../data/debugData_sort').default;
  // questionsData = require('../../data/debugData_yesno').default;
  /* eslint-enable */
}
/* eslint-disable */

questionsData = questionsData.map((question, id) => {
  question.id = id;
  return question;
});

// Time pseudo code
/*
✅Time starts when first question starts with that questions time

when question callback return true OR false, stop the timer

when the next question shows up. Restart the timer with the new time

*/


/*
State cycle (maybe convert to a state machine?):

- on mount: this.handleTitle -> wait -> this.handleStartQuestion
- start question: show question, hide title, start timer
  * on vapor button
    - pause the timer for 10s extra
      - if the user finish within. reset that timeout
  * on correct answer:
    - Show success screen
    - Show next question (go back to "on mount")
      - Show game over screen on last question

  * on wrong answer
    - Show wrong screen
  * on time out
    - Show time out screen
  - Show game over screen
*/

// Will probably be used to shuffle the data in different levels
const shuffleQuestions = (questions) => {
  return shuffle(questions);
};

// Return the quiz component based on name
const RenderQuizComponent = {
  sort: (question, callback, start) => <Sort key={question.id} question={question} callback={callback} start={start} />,
  multichoice: (question, callback, start) => <MultiChoice key={question.id} data={question} callback={callback} start={start} />,
  yesno: (question, callback, start) => <YesNo key={question.id} question={question} callback={callback} start={start} />,
  chooseimage: (question, callback, start) => <ChooseImage key={question.id} callback={callback} data={question} start={start} />,
  pair: (question, callback, start) => <Pair key={question.id} data={question} callback={callback} start={start} />,
};

// Animation wrapper components
// const QuestionWrapper = posed.div({
//   enter: { opacity: 1, x: 0 },
//   exit: { opacity: 0, x: -100 }
// });


export default class CreateQuiz extends Component {
  constructor(props) {
    super(props);

    const points = window.localStorage.getItem('points');

    this.state = {
      startTimer: false,
      isQuizOver: false,
      points: points || 0,
      currentQuestion: questionsData[0],
      currentQuestionId: 0,
      showEndScreen: false,
      showTimeUpScreen: false,
      titleFullscreen: true,
      resetTimer: false,
      pauseTimer: false,
      showCorrectOverlay: false,
      showWrongOverlay: false,
    };
  }

  // Here we do stuff that happens
  // when the user answers correct,
  // false or time out on a question
  handleQuestion = (correctAnswer, e) => {
    if (e) e.preventDefault();

    // check if it's the last question
    const isLastQuestion = this.state.currentQuestionId >= this.questionsDataFormated.length - 1;

    // Set currentQuestion, handle overlays etc
    const isQuizOver = !correctAnswer || isLastQuestion;

    this.setState({
      // currentQuestionId: currentQuestionId,
      // Show the next question
      // currentQuestion: this.questionsDataFormated[currentQuestionId],
      points: correctAnswer ? this.state.points + 1 : this.state.points,
      startTimer: false, // stop the timer
      resetTimer: true,
      showCorrectOverlay: correctAnswer,
      showWrongOverlay: !correctAnswer,
    });

    // Show the next question or endscreen

    this.timeout1 = setTimeout(() => {
      if (isQuizOver) {
        this.setState({
          // If the user clicks corrent on the last question, show the end screen
          showEndScreen: isQuizOver,
          isQuizOver,
        });
      } else {
        this.showNextQuestion();
      }
    }, 600); // delayed to wait for overlay anim

  };

  showNextQuestion = () => {

    // Set the next question
    const currentQuestionId = this.state.currentQuestionId + 1;

    this.setState({
      currentQuestionId,
      currentQuestion: this.questionsDataFormated[currentQuestionId],
    });

    this.handleTitle();
  }

  // When the endscreen has finisched animatin (= covering the whole window),
  // shuffle the questions so they are ready when we wanna try again
  prepareNextRound = () => {
    if (this.state.isQuizOver) {
      this.questionsData = shuffleQuestions(this.questionsDataFormated);
      this.setState({
        currentQuestion: this.questionsData[0],
        currentQuestionId: 0,
        resetTimer: true,
        pauseTimer: false,
      });
    }
  };

  // When the user wanna try again
  handleReset = () => {
    this.setState({
      points: 0,
      showEndScreen: false,
      showTimeUpScreen: false,
      isQuizOver: false,
      startTimer: false,
    });
    this.handleTitle();
  };

  handleTimer = (isTimeEnded) => {
    if (isTimeEnded) {
      this.setState({
        showTimeUpScreen: true,
        isQuizOver: true,
      });
    }
  };

  handleStartQuestion = () => {
    this.setState({
      titleFullscreen: false,
      startTimer: true,
      resetTimer: false,
      pauseTimer: false,
    });
  }

  handleTitle = () => {
    this.setState({
      titleFullscreen: true,
      resetTimer: true,
      pauseTimer: false,
    });

    this.titleTimout = setTimeout(() => {
      this.handleStartQuestion();
    }, 2000);
  }

  handleOverlays = (msg) => {
    if (msg === 'animCompleted') {
      this.setState({
        showCorrectOverlay: false,
        showWrongOverlay: false,
      });
    }
  }

  componentDidMount() {
    this.handleTitle();
  }

  componentWillUnmount() {
    clearTimeout(this.timeout1);
    clearTimeout(this.titleTimout);
  }

  render() {
    const { points } = this.state;

    const { titleFullscreen } = this.state;

    return (
      <main>
        <div
          className="title_modal__bg"
        />

        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <h1>Debug view</h1>
          <p>Scroll down to see all questions. Open console (⎇⌘J Chrome, ⎇⌘K Firefox)<br /> to try out correct and wrong answers</p>
        </div>
        {/* <PoseGroup> */}
        {
          questionsData.map((question, index) => (
            <Fragment key={index}>
              <div
                className="title_modal"
              >
                <h2 className="question_title h3" style={{ textAlign: 'center' }}>{index + 1}: {question.title}</h2>
              </div>
              <div className="question">
                {RenderQuizComponent[question.type](question, (correct) => { console.log('You answered ', correct); }, this.state.startTimer)}
              </div>
            </Fragment>
          ))
        }
        {/* </PoseGroup> */}

        <Endscreen show={this.state.isQuizOver} className="endscreen" onPoseComplete={this.prepareNextRound}>
          {this.state.showTimeUpScreen && <h2>Time up!</h2>}
          {points > 0 ? <h1 className="align-center">Grattis! Du fick<br />{points} poäng</h1> : <h2 className="align-center">Hoppsan, du fick <br />inga poäng. Försök igen 🙃</h2>}
          <button className="h4 temp-button" onClick={this.handleReset}>Kör igen</button>
        </Endscreen>

        <OverlaySuccess
          show={this.state.showCorrectOverlay}
          callback={this.handleOverlays}
        />
        <OverlayWrong
          show={this.state.showWrongOverlay}
          callback={this.handleOverlays}
        />

        <Footer />

      </main >
    );
  }
}

/* eslint-enable */
