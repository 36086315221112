import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Header = props => (
  <header className="header">
    <div className="header__logo">
      <svg width="113" height="33" viewBox="0 0 113 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M63.6781 4.21643C63.3698 3.26988 63.8983 2.75359 64.8231 2.75359H67.2451V1.11864H51.6118V2.75359H55.1789C56.1477 2.75359 56.9844 3.26988 57.2927 4.21643L66.7167 32.6128H70.2837L76.7132 12.9505L82.9224 32.6128H86.9739L95.6493 4.90482C96.0456 3.52803 97.0144 2.79661 98.4677 2.79661C99.4365 2.79661 99.9209 3.48501 99.9209 4.43155V29.3429C99.9209 30.2894 99.4365 30.9778 98.4677 30.9778H93.2272V32.6128H113V30.9778H107.76C106.791 30.9778 106.306 30.2464 106.306 29.3429V4.43155C106.306 3.48501 106.835 2.75359 107.76 2.75359H113V1.11864H87.7225V2.75359H90.673C92.7868 2.75359 93.5795 3.8292 92.963 5.89439L86.9739 25.0834L80.3683 4.21643C80.06 3.26988 80.5885 2.75359 81.5133 2.75359H85.3005V1.11864H69.6672V2.75359H71.869C72.8379 2.75359 73.6746 3.26988 73.9388 4.21643L75.5242 9.25033L70.4599 24.6532L63.6781 4.21643ZM33.3363 16.8657C33.3363 25.0404 36.4189 31.193 41.7915 31.193C47.12 31.193 50.2026 25.0404 50.2026 16.8657C50.2026 8.64798 47.12 2.49544 41.7915 2.49544C36.463 2.49544 33.3363 8.64798 33.3363 16.8657ZM14.7525 0.774446C17.1306 0.774446 18.7159 1.41982 20.1251 2.28031C22.2389 3.44198 23.56 3.01173 23.56 1.11864V0H25.2775V13.6389H23.7362C22.8554 6.41069 19.8609 2.49544 14.7525 2.49544C8.80748 2.49544 6.29735 8.64798 6.29735 16.8657C6.29735 25.0404 8.80748 31.1499 14.7525 31.1499C22.0627 31.1499 25.5417 25.9439 26.8188 16.8657C27.2592 13.6389 27.2592 13.6389 27.2592 13.6389C28.3601 6.10952 33.5565 0.774446 41.7475 0.774446C51.0394 0.774446 56.7202 7.70143 56.7202 16.9087C56.7202 26.116 51.0834 33 41.7475 33C34.4373 33 29.5051 28.8266 27.7436 22.588C27.6995 22.3299 27.6995 22.3299 27.6995 22.3299C27.6995 22.3299 27.6995 22.3299 27.6115 22.7601C25.85 28.0522 22.6793 32.957 14.7525 32.957C5.4166 32.957 0 26.073 0 16.8657C0 7.65841 5.02026 0.774446 14.7525 0.774446Z" fill="currentColor" />
      </svg>
    </div>
    { props.children }
  </header>
);

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Header.defaultProps = {
  children: null,
};

export default Header;
