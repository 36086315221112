import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';

const Footer = ({ relative }) => {
  return (
    <footer className={`footer ${relative ? 'footer--relative' : ''}`} />
  );
};

Footer.propTypes = {
  relative: PropTypes.bool,
};

Footer.defaultProps = {
  relative: false,
};

export default Footer;
