import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { Howl } from 'howler';
import App from './App';
import * as serviceWorker from './serviceWorker';


// usage: window.soundeffects.play('levelup');
window.soundeffects = new Howl({
  src: ['./sounds/covi.mp3'],
  sprite: {
    start: [0, 4160], // [offset, duration]
    correct: [4500, 500],
    wrong: [5300, 300],
    endgamesuccess: [5600, 10010],
    endgamefail: [15300, 6500], // 15210 - 6130
    timeup: [22040, 2040],
    timeupSciFi: [24090, 1260],
  },
});
// Test / play sounds like this:
// window.soundeffects.play('endgamesuccess');

ReactDOM.render(<App />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
