import React, { Component } from 'react';
import posed from 'react-pose';

import './index.scss';

const LogoAnimation = posed.div({
  init: {
    scale: 0.9,
  },
  animate: {
    scale: 1,
    transition: {
      type: 'spring',
      duration: 300,
      delay: 800,
    },
  },
});

const TextLeft = posed.g({
  init: {
    x: 50,
    scale: 1,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 300,
      delay: 600,
      // ease: 'easeOut',
    },
  },
});

const TextRight = posed.g({
  init: {
    x: -50,
    scale: 1,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 300,
      delay: 600,
      // ease: 'easeOut',
    },
  },
});


const QuestionMark = posed.path({
  init: {
    opacity: 0,
    d: 'M261.7,28.4c0-1.2,0.2-2.1,0.4-2.8c0.3-0.7,0.8-1.4,1.6-2.1c0.6-0.5,1.1-1,1.5-1.7c0.2-0.4,0.4-0.9,0.4-1.4 c0-1.4-0.7-2.1-2-2.1c-0.6,0-1.1,0.2-1.5,0.6c-0.4,0.4-0.6,1-0.6,1.6h-3.7c0-1.6,0.5-2.9,1.6-3.8c1-0.9,2.4-1.4,4.2-1.4 s3.2,0.4,4.2,1.3c1,0.9,1.5,2.1,1.5,3.7c0,0.7-0.2,1.4-0.5,2.1c-0.3,0.7-0.9,1.3-1.7,2.1l-1,1c-0.6,0.6-1,1.3-1.1,2.2l-0.1,0.8 L261.7,28.4L261.7,28.4L261.7,28.4z',
    fill: '#fff',
    transition: {
      default: { ease: 'easeOut', duration: 500 },
    },
  },
  animate: {
    opacity: 1,
    d: 'M259.5,36c0-2.6,0.3-4.7,0.9-6.1c0.6-1.5,1.8-3,3.5-4.5c1.3-1,2.4-2.3,3.3-3.6c0.5-0.9,0.8-1.9,0.8-3c0-3.1-1.4-4.7-4.3-4.7c-1.4,0-2.4,0.4-3.2,1.3c-0.8,1-1.3,2.2-1.3,3.4h-8c0.1-3.5,1.1-6.3,3.4-8.2c2.2-2,5.3-3,9.2-3s7,1,9.1,2.8c2.2,1.9,3.2,4.5,3.2,8c0,1.6-0.3,3-1.1,4.5c-0.7,1.4-1.9,2.9-3.6,4.7l-2.2,2.2c-1.4,1.2-2.2,2.9-2.4,4.7l-0.1,1.7L259.5,36L259.5,36L259.5,36z',
    transition: {
      opacity: { ease: 'easeOut', duration: 300, delay: 600 },
      d: { ease: 'easeOut', duration: 300 },
      default: { ease: 'linear', duration: 0 },
    },
  },
});

const Dot = posed.path({
  init: {
    opacity: 0,
    d: 'M247.6,44.6c0-4.2,1.4-7.7,4.4-10.5c3-2.9,7.1-4.4,11.2-4.2c4.4,0,8.2,1.4,11.2,4.2c2.9,2.7,4.4,6.5,4.4,10.5c0,4.2-1.4,7.7-4.2,10.3c-3,2.9-7.1,4.4-11.2,4.2c-4.7,0-8.4-1.4-11.2-4.2C249.1,52.4,247.4,48.5,247.6,44.6z',
    fill: '#fff',
  },
  animate: {
    opacity: 1,
    d: 'M258.7,44.5c0-1.2,0.4-2.2,1.3-3c0.9-0.8,2-1.3,3.2-1.2c1.3,0,2.4,0.4,3.2,1.2c0.8,0.8,1.3,1.9,1.3,3c0,1.2-0.4,2.2-1.2,3c-0.9,0.8-2,1.3-3.2,1.2c-1.4,0-2.4-0.4-3.2-1.2C259.1,46.7,258.6,45.6,258.7,44.5z',
    transition: {
      duration: 300,
      delay: 500,
      // ease: 'easeOut',
    },
  },
});

class ChallengeLogo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startAnimation: false,
    };
  }

  componentDidMount() {
    this.timeout1 = setTimeout(() => {
      this.setState({
        startAnimation: true,
      });
    }, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout1);
  }

  render() {
    return (
      <LogoAnimation pose={this.state.startAnimation ? 'animate' : 'init'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 572 76"
        >
          <TextLeft pose={this.state.startAnimation ? 'animate' : 'init'}>
            <path
              d="M60.3,49.8c-0.5,7.8-3.5,14-8.7,18.5s-12.1,6.8-20.6,6.8c-9.3,0-16.7-3.1-22-9.4c-5.3-6.3-8-14.9-8-25.9v-4.5
                          c0-7,1.2-13.2,3.7-18.5c2.2-5,5.9-9.3,10.5-12.3c4.6-2.8,9.9-4.3,15.9-4.3c8.4,0,15.1,2.2,20.3,6.8c5.1,4.5,8.1,10.8,8.9,18.9h-15
                          c-0.3-4.7-1.7-8.1-3.9-10.2s-5.7-3.2-10.3-3.2c-5,0-8.7,1.8-11.2,5.4c-2.5,3.6-3.8,9.1-3.9,16.7v5.5c0,7.8,1.2,13.6,3.6,17.2
                          c2.4,3.6,6.1,5.5,11.3,5.5c4.7,0,8.1-1.1,10.4-3.2c2.3-2.1,3.6-5.4,3.9-9.8L60.3,49.8L60.3,49.8L60.3,49.8z M129.4,74.1h-15V42.9
                          H85.1v31.2h-15V1.2h15v29.5h29.3V1.2h15L129.4,74.1L129.4,74.1z M184.5,59.1h-26.3l-5,15h-15.9l27.1-72.8h13.9L205.5,74h-15.9
                          L184.5,59.1L184.5,59.1z M162.2,46.9h18.2l-9.1-27.2L162.2,46.9L162.2,46.9z M227.2,62h31.7l19.4,12h-66.1V1.2h15L227.2,62
                          L227.2,62z"
              fill="#fff"
            />
          </TextLeft>
          <TextRight pose={this.state.startAnimation ? 'animate' : 'init'}>
            <path
              d="M299.5,1.2h15V74H277l-4.7-12h27.3L299.5,1.2L299.5,1.2z M366.9,42.5h-28.8V62h33.8v12h-48.8V1.2h48.7v12.2h-33.7v17.4
                          h28.8L366.9,42.5L366.9,42.5z M439.9,74.1h-15l-29.2-47.9v47.9h-15V1.2h15l29.3,48v-48h14.9L439.9,74.1L439.9,74.1z M510.6,64.8
                          c-2.7,3.2-6.5,5.7-11.4,7.5c-5.3,1.8-10.8,2.7-16.4,2.7c-6.3,0-11.8-1.3-16.6-4.1c-4.8-2.8-8.6-7-11-12c-2.6-5.2-3.9-11.3-4-18.4
                          v-4.9c0-7.3,1.2-13.6,3.7-18.9c2.2-5,5.9-9.3,10.6-12.2c4.6-2.8,10-4.2,16.2-4.2c8.6,0,15.4,2,20.3,6.2c4.9,4.1,7.8,10.1,8.6,18
                          H496c-0.7-4.2-2.2-7.2-4.4-9.1c-2.3-2-5.4-2.9-9.4-2.9c-5.1,0-9,1.9-11.6,5.7c-2.6,3.9-4,9.5-4,17.1v4.7c0,7.6,1.4,13.4,4.3,17.3
                          c2.9,3.9,7.2,5.9,12.8,5.9c5.6,0,9.7-1.2,12-3.6V46.9H482v-11h28.7L510.6,64.8L510.6,64.8L510.6,64.8z M566.5,42.5h-28.8V62h33.8
                          v12h-48.8V1.2h48.7v12.2h-33.7v17.4h28.8L566.5,42.5L566.5,42.5z"
              fill="#fff"
            />
          </TextRight>


          <QuestionMark pose={this.state.startAnimation ? 'animate' : 'init'}>
            <path d="M259.5,36c0-2.6,0.3-4.7,0.9-6.1c0.6-1.5,1.8-3,3.5-4.5c1.3-1,2.4-2.3,3.3-3.6c0.5-0.9,0.8-1.9,0.8-3
            c0-3.1-1.4-4.7-4.3-4.7c-1.4,0-2.4,0.4-3.2,1.3c-0.8,1-1.3,2.2-1.3,3.4h-8c0.1-3.5,1.1-6.3,3.4-8.2c2.2-2,5.3-3,9.2-3s7,1,9.1,2.8
            c2.2,1.9,3.2,4.5,3.2,8c0,1.6-0.3,3-1.1,4.5c-0.7,1.4-1.9,2.9-3.6,4.7l-2.2,2.2c-1.4,1.2-2.2,2.9-2.4,4.7l-0.1,1.7L259.5,36
            L259.5,36L259.5,36z"
            />
          </QuestionMark>
          <Dot pose={this.state.startAnimation ? 'animate' : 'init'}>
            {/* <path
              className="challenge-logo__dot"

            /> */}
          </Dot>


        </svg>

      </LogoAnimation>
    );
  }
}

export default ChallengeLogo;
