import React, { Component } from 'react';
import { shuffle } from 'helpers';
import PropTypes from 'prop-types';

import './index.scss';

class MultiChoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: shuffle(this.props.data.options),
      x: 0,
      y: 0,
    };
  }

  render() {
    const { correct } = this.props.data;

    const letters = ['1', 'X', '2', '3', '4'];
    const { x, y } = this.state;

    return (
      <form className="multiChoice block-width-medium">
        {this.state.options.map((option, index) => (
          <label
            onClick={(e) => { this.setState({ x: e.pageX, y: e.pageY }); }}
            htmlFor={`${this.props.data.id}-${index}-MC`}
            key={index}
            className="multiChoice__item h3"
          >
            <div className="multiChoice__id">
              <span className="multiChoice__id-circle">
                <span>{letters[index]}</span>
              </span>
            </div>
            <input
              id={`${this.props.data.id}-${index}-MC`}
              className="visuallyhidden"
              type="radio"
              value={option}
              onChange={(e) => {
                this.props.callback(correct === option, e, { x, y });
              }}
            />
            <span className="multiChoice__text">{option}</span>
          </label>
        ))}
      </form>
    );
  }
}

MultiChoice.displayName = 'MultiChoice';

MultiChoice.propTypes = {
  data: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
};

export default MultiChoice;
